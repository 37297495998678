import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Images from "../../../utilities/images";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import moment from "moment";
import CustomModal from "../../Modal/CustomModal";
import { Spinner } from "react-bootstrap";
import {
  activeInactiveUser,
  getAllUsers,
  updateUserStatus,
} from "../../../redux/slices/web";
import ViewUserInfo from "../../Modal/viewUserInfo";
import { useWebSelector } from "../../../redux/selector/web";

const Users = () => {
  const toastId = useRef(null);
  document.title = "Senior Technician";
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [allUsers, setAllusers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState("");
  const [search, setSearch] = useState("");
  const [user, setUser] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    userId: "",
  });

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      userId: "",
    });
    setKey(Math.random());
  };
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  //open modal
  const handleOpenModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      userId: data,
    });
    setKey(Math.random());
    setUser(data || "");
  };

  // update user Status
  const handleUpdateUserStatus = (id, status) => {
    if (status === "0") {
      handleOpenModal("veiwUser", id);
    } else if (status === "3") {
      let params = {
        user_id: id,
      };
      dispatch(
        activeInactiveUser({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              handleGetAllUser();
            }
          },
        })
      );
    } else {
      let params = {
        user_id: id,
        status: status,
      };
      dispatch(
        updateUserStatus({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              handleGetAllUser();
            }
          },
        })
      );
    }
  };

  // table columns
  const columns = [
    {
      name: "User Profile",
      selector: (row) => (
        <div className="tableRow">
          <img
            src={row.profile_pic || Images?.DummyImage}
            alt="User Profile"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        </div>
      ),
      reorder: true,
    },
    ,
    {
      name: "Full Name",
      selector: (row) => row.name || "-----",
      reorder: true,
    },
    {
      name: "Date Of Birth",
      selector: (row) =>
        row?.dob ? moment(row.dob).format("DD/MM/YYYY") : "-----",
      reorder: true,
    },
    {
      name: "Ph No.",
      selector: (row) => row?.phone_number,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2 ">
          <div
            className={
              row?.is_admin_verified === 2 || row?.status === 0
                ? "inActive-user active-user"
                : row?.is_admin_verified === 0
                ? "pending-user "
                : "active-user"
            }
          ></div>
          <p
            className={
              row?.is_admin_verified === 1 && row?.status === 1
                ? "active-text mb-0 text-capitalize"
                : row?.is_admin_verified === 0
                ? "pendig-text mb-0 text-capitalize"
                : " in-active-text text-capitalize mb-0"
            }
          >
            {row?.is_admin_verified === 0
              ? "Pending"
              : row?.is_admin_verified === 1 && row?.status === 1
              ? "Active"
              : row?.is_admin_verified === 1 && row?.status === 0
              ? "In-Active"
              : row?.is_admin_verified === 2
              ? "Reject"
              : ""}
          </p>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="dropdown filterDropdownChurchList">
            <button
              onClick={() => {
                if (!row?.name) {
                  showToast("User has not completed profile yet");
                }
              }}
              className="toggle-btn"
              type="button"
              {...(row?.name && { "data-bs-toggle": "dropdown" })}
              aria-expanded="false"
              data-toggle="tooltip"
              title={!row?.name ? "User has not completed profile yet" : ""}
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>
            <ul className="dropdown-menu">
              {row?.is_admin_verified === 0 ? (
                <>
                  <li onClick={() => handleUpdateUserStatus(row?.id, "0")}>
                    <Link className="dropdown-item font-14 userText" to="#">
                      View
                    </Link>
                  </li>
                  <li onClick={() => handleUpdateUserStatus(row?.id, "1")}>
                    <Link className="dropdown-item font-14 userText" to="#">
                      Accept
                    </Link>
                  </li>
                  <li onClick={() => handleUpdateUserStatus(row?.id, "2")}>
                    <Link className="dropdown-item font-14 userText" to="#">
                      Reject
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li onClick={() => handleUpdateUserStatus(row?.id, "0")}>
                    <Link className="dropdown-item font-14 userText" to="#">
                      View
                    </Link>
                  </li>
                  {row?.is_admin_verified !== 2 && (
                    <li onClick={() => handleUpdateUserStatus(row?.id, "3")}>
                      <Link className="dropdown-item font-14 userText" to="#">
                        {row?.status === 0 ? "Active" : "In-Activate"}
                      </Link>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        );
      },
    },
  ];

  // get all users
  useEffect(() => {
    handleGetAllUser();
  }, [currentPage, perPage, search]);

  // get all users
  const handleGetAllUser = () => {
    let params = {
      page: currentPage,
      perPage: perPage,
      search: search || undefined,
    };
    dispatch(
      getAllUsers({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setAllusers(res?.data?.data?.users);
            setPageCount(res?.data?.data?.totalPages);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Users</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="d-flex commonFilterHeader justify-content-end  userSearch position-relative">
            {/* <p className="commonBox_Heading">Filter by status</p> */}
            <i class="fa-solid fa-magnifying-glass searchIcon"></i>
            <input
              type="search"
              className="filterSearch "
              placeholder="search by Name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="text-center">
            {" "}
            {loading && !modalDetail.show && <Spinner />} {/* Top loader */}
          </div>
          <div className="data-table">
            <DataTable
              columns={columns}
              data={allUsers}
              defaultSortFieldId={1}
              highlightOnHover={true}
              noDataComponent="No results found"
            />
          </div>

          {allUsers && allUsers?.length > 0 && (
            <div className=" paginateSec">
              <ReactPaginate
                className="paginationBox mb-0"
                nextLabel=" >"
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </section>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "congratulation"
            ? "commonWidth  customContent"
            : ""
        }
        ids={modalDetail.flag === "veiwUser" ? "createPassword" : ""}
        child={
          modalDetail.flag === "veiwUser" ? (
            <ViewUserInfo
              userId={modalDetail?.userId}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "veiwUser" ? (
            <>
              <h2 className="profile_modal_heading">User Info</h2>
              <p onClick={() => handleOnCloseModal()}>
                <i class="fa fa-xmark"></i>
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Users;
