import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "sweetalert2";
import CustomModal from "../../Modal/CustomModal";
import { Spinner } from "react-bootstrap";
import { deleteEvent, getAllEvents } from "../../../redux/slices/web";
import { useWebSelector } from "../../../redux/selector/web";

const Events = () => {
  document.title = "Events";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [allEvents, setAllEvents] = useState([]);
  const [user, setUser] = useState("");
  const [key, setKey] = useState(Math.random());
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState("");
  console.log(pageCount, "pageCount");

  const [search, setSearch] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // logout handler
  const handleDeleteEvent = (id) => {
    Swal.fire({
      className: "swal-logout",
      title: "Are you sure?",
      text: "That you want to delete event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#4A69F5",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: id,
        };

        dispatch(
          deleteEvent({
            ...params,
            cb(res) {
              if (res?.status === 200) {
                handleGetEvents();
                navigate("/event");
              }
            },
          })
        );
      }
    });
  };

  // table columns
  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <div className="tableRow">
          <img
            src={row.image}
            alt="image"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        </div>
      ),
      reorder: true,
    },
    {
      name: "Title",
      selector: (row) =>
        row.title?.length > 15 ? row.title.slice(0, 15) + "..." : row.title,
      reorder: true,
    },
    {
      name: "Description",
      selector: (row) =>
        row?.description?.length > 15
          ? row.description.slice(0, 15) + "..."
          : row.description,
      reorder: true,
    },
    {
      name: "Price",
      selector: (row) => `${row?.price || 0} INR\\-`,
      reorder: true,
    },
    {
      name: "Location",
      selector: (row) =>
        row?.location?.length > 15
          ? row.location.slice(0, 15) + "..."
          : row.location,
      reorder: true,
    },
    {
      name: "Total Members",
      selector: (row) => row?.total_numbers,
      reorder: true,
    },
    {
      name: "Male",
      selector: (row) => row?.male_count,
      reorder: true,
    },
    {
      name: "Female",
      selector: (row) => row?.female_count,
      reorder: true,
    },
    {
      name: "Start Time",
      selector: (row) => {
        const timing = typeof row?.timing === "string" ? JSON.parse(row.timing) : row?.timing;
        return timing?.startTime ? moment(timing.startTime).format("YYYY/MM/DD HH:mm") : "N/A";
      },
      reorder: true,
    },
    {
      name: "End Time",
      selector: (row) => {
        const timing = typeof row?.timing === "string" ? JSON.parse(row.timing) : row?.timing;
        return timing?.endTime ? moment(timing.endTime).format("YYYY/MM/DD HH:mm") : "N/A";
      },
      reorder: true,
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      cell: (row) => (
        <div className="d-flex align-items-center btnGroup">
          <button
            className="viewEdtDelBtn"
            onClick={() => navigate("/viewEvent", { state: { row } })}
          >
            <i class="fa-regular fa-eye fa-lg"></i>
          </button>
          <button
            className="viewEdtDelBtn"
            onClick={() =>
              navigate("/addEvent", { state: { row, flag: "update" } })
            }
          >
            <i class="fa-solid fa-pen fa-lg"></i>
          </button>
          <button
            className="viewEdtDelBtn"
            onClick={() => handleDeleteEvent(row?.id)}
          >
            <i class="fa-solid fa-trash fa-lg"></i>
          </button>
        </div>
      ),
      reorder: true,
    },
  ];

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //open modal
  const handleOpenModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setUser(data || "");
  };

  // get all users
  useEffect(() => {
    handleGetEvents();
  }, [currentPage, perPage, search]);

  // get all users
  const handleGetEvents = () => {
    let params = {
      page: currentPage,
      perPage: perPage,
      search: search || undefined,
    };
    dispatch(
      getAllEvents({
        ...params,
        cb(res) {
          console.log(res, "resresres");

          if (res?.status === 200) {
            setAllEvents(res?.data?.data?.events);
            setPageCount(res?.data?.data?.totalPages);
          }
        },
      })
    );
  };

  // get all event
  useEffect(() => {
    handleGetEvents();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Events</h1>
              </div>
              <div className="col-sm-6" onClick={() => navigate("/addEvent")}>
                <div className="d-flex justify-content-end">
                  <button className="common-btn">
                    <i className="fa fa-user-plus me-2"></i>
                    Add Event
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="d-flex commonFilterHeader justify-content-end  userSearch position-relative">
            {/* <p className="commonBox_Heading">Filter by status</p> */}
            <i class="fa-solid fa-magnifying-glass searchIcon"></i>
            <input
              type="search"
              className="filterSearch "
              placeholder="search by title"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/*   */}
          <div className="text-center">
            {" "}
            {loading && !modalDetail.show && <Spinner />} {/* Top loader */}
          </div>

          <div className="data-table">
            <DataTable
              columns={columns}
              data={allEvents}
              defaultSortFieldId={1}
              highlightOnHover={true}
              noDataComponent="No results found"
            />
          </div>

          {allEvents && allEvents?.length > 0 && (
            <div className=" paginateSec">
              <ReactPaginate
                className="paginationBox mb-0"
                nextLabel=" >"
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Events;
