import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ViewEvent = () => {

  const location = useLocation();
  const { row } = location?.state || "";
console.log(location,"rowrowrow");
const [eventData, setEventData] = useState();
console.log(eventData,"allEvents");

  // Parse the string to an object
  const timing =
    typeof eventData?.row?.timing === "string" ? JSON.parse(eventData?.row?.timing) : eventData?.row?.timing;
  console.log(timing, "timing");

  // Extract and format the start and end times
  const startTime = new Date(
    timing?.start || timing?.startTime
  ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  
  const endTime = new Date(timing?.end || timing?.endTime).toLocaleTimeString(
    [],
    { hour: "2-digit", minute: "2-digit" }
  );

  useEffect(()=>{
    setEventData(location?.state)
  },[])

  return (
    <div className="content-wrapper">
      <section className="addEventSection">
        <h2 className="addEventTitle">View Event</h2>
        <div className="addEventContainer">
          <div className="container-fluid">
            <div className="eventImgContainer ">
              <div className="uploadImg_Parent border_None">
                <div className="showImg_Parent">
                  <img src={eventData?.row?.image} alt="Preview" className="previewImg " />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt ms-0">Event title:</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.title}</h5>
                </div>
              </div>
              <div className="col-md-6 selDateTime">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Location</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.location}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0"> Date </label>
                  <h5 className="event_InfoTxt">{moment(eventData?.row?.date).format("YYYY-MM-DD")}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0"> Start Date & Time </label>

                  <h5 className="event_InfoTxt">{timing?.start || timing?.startTime}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0"> End Time </label>

                  <h5 className="event_InfoTxt">{timing?.end || timing?.endTime}</h5>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Ticket Price </label>
                  <h5 className="event_InfoTxt">{eventData?.row?.price}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Total Members</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.total_numbers}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Total Male</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.male_count}</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Total Female</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.female_count}</h5>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelTxt  ms-0">Description</label>
                  <h5 className="event_InfoTxt">{eventData?.row?.description}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewEvent;
